import React, {useState} from 'react';
import {modalStyleBase} from '../utils'
import ModalCloseButton from './ModalCloseButton';
const HintModal = ({
  elementProp,
  top,
  left,
  setShowHintModal,
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
        'width': 'auto'
      }
    ))

  return (
    <div className='hint-modal' id='hint-modal' style={modalStyle}>
      <ModalCloseButton click={setShowHintModal} />
      'e' edit | 'l' link |  's' style | 'c' color | 'f' font | 'd' drop-shadow | 'x' crop  
    </div>
  )
}

export default HintModal