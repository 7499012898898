import React from 'react'
import './App.scss';
import Canvas from './components/Canvas';
const App = () => {
  // const [username, setUsername] = useState('jay')
  // const [project, setProject] = useState(window.location.href.split('/')[4] || 'project_1')
  return (
    <div className="App">
      {/* <Canvas project={project} /> */}
      <Canvas />
    </div>
  );
};
export default App;
