import React, {useState, useEffect, useRef} from 'react';
import { SketchPicker } from 'react-color';
import NumberScroll from './NumberScroll';
import {modalStyleBase, copyStyle} from '../utils.js';
import { dbCall } from '../data';
import './ColorEditModal.scss';
import ModalCloseButton from './ModalCloseButton.jsx';

const ColorEditModal = ({
  elementProp,
  top,
  left,
  setShowColorEditModal,
  username,
  handleElementMouseOver,
  handleKeyDown,
  handlePreviewDrag,
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const [element, setElement] = useState(elementProp);
  const [whichStyle, _setWhichStyle] = useState('color')
  const [gradientStartColor, setGradientStartColor] = useState()
  const [gradientEndColor, setGradientEndColor] = useState()
  const [gradientDegree, setGradientDegree] = useState('')
  const [currentColor, _setCurrentColor] = useState()
  const [hoveredColorBox, setHoveredColorBox] = useState()
  const [favoriteColors, setFavoriteColors] = useState()
  const [updateColor, setUpdateColor] = useState(0)

  const currentColorRef = useRef(currentColor)
  const setCurrentColor = data => {
    currentColorRef.current = data;
    _setCurrentColor(data)
  }

  const whichStyleRef = useRef(whichStyle)
  const setWhichStyle = data => {
    whichStyleRef.current = data;
    _setWhichStyle(data)
  }

  const handleChangeComplete = (color) => {
    let hex, r, g, b
    if (!color.hex) {
      let splitRGB = color.split(',')
      r = splitRGB[0].replace(/\D/g,'')
      g = splitRGB[1].replace(/\D/g,'')
      b = splitRGB[2].replace(/\D/g,'')
      hex = (r, g, b) => '#' + [r, g, b].map(x => Number(x).toString(16).padStart(2, '0')).join('')
    }
    element.style[whichStyleRef.current] = color.hex || hex(r, g, b)
    setCurrentColor(color.hex)
  }

  const setTransparentBackground = () => {
    element.style.background = 'transparent'
  }

  const handleSet = (number, attr, suffix) => {
    setGradientDegree(number.toString() + suffix)
  }

  const setDropShadowColor = () => {
    if (element.style.filter) {
      let splitOpenP = element.style.filter.split('w(')
      let splitCloseP = splitOpenP[1].split(')')
      element.style.filter = splitOpenP[0] + 'w(' + currentColorRef.current +  splitCloseP[1] + ')'
    }
  }

  const deleteColor = (element, keyPressed) => {
    console.log("do I know keypress? ", keyPressed)
    console.log("do I know element? ", element)
  }

  const addFavoriteColor = (e) => {
    if (currentColorRef.current) {
      // const newFavBox = document.createElement('div')
      // copyStyle(newFavBox,e.target.style)
      // newFavBox.style.background = currentColorRef.current
      // newFavBox.onclick = (e) => handleChangeComplete(e.target.style.background)
      // newFavBox.onmouseover = (e) => handleElementMouseOver(e)
      // newFavBox.setAttribute('name', 'added-color-box')
      // document.getElementById('fav-color-container').appendChild(newFavBox)
      // // let dataToSave = newFavBox.outerHTML
      // console.log("currentColorRef.current is ", currentColorRef.current)
      // newFavBox.remove()
      // console.log("dataToSave is ", dataToSave)
      let dataToSave = currentColorRef.current + ";"
      let params = {
        'username': username,
        'data': dataToSave 
      }
      dbCall('/save_color', params).then(data => {
        if (data.message === 'success') {
          console.log("color saved")
          setUpdateColor(updateColor + 1)
        }
      })
    }
  }

  useEffect(() => {
    let params = {
      'username': username
    }
    dbCall('/get_colors', params).then(data => {
      if (data.message === 'success') {
        const colorsArr = data.html.split(';')
        console.log("colorsArra is ", colorsArr)
        setFavoriteColors(colorsArr.map((color, i) => 
          // <div key={i} onClick={(e) => handleChangeComplete(e.target.style.backgroundColor)} onMouseOver={(e) => handleElementMouseOver(e)} style={{'backgroundColor': color, 'height': '16px', 'width': '16px', 'cursor': 'pointer', 'outline': 'none', 'borderRadius': '3px', 'boxShadow': {color} + ' 0px 0px 0px 1px inset'}}></div>
          <div key={i} onClick={(e) => handleChangeComplete(e.target.style.backgroundColor)} style={{'backgroundColor': color, 'height': '16px', 'width': '16px', 'cursor': 'pointer', 'outline': 'none', 'borderRadius': '3px', 'boxShadow': {color} + ' 0px 0px 0px 1px inset'}}></div>
        ))
        // setFavoriteColors(colorsArr.map((color, i) => <div id={i} style={{'backgroundColor':color}}>{color}</div>))
      }
    })
  },[updateColor])

  useEffect(() => {
    setTimeout(() =>
    Array.from(document.getElementById('fav-color-container').getElementsByTagName('*')).forEach(element => {
      if (element.getAttribute('name') === 'added-color-box') {
        element.onclick = (e) => handleChangeComplete(e.target.style.backgroundColor)
      }
    }), 500)
  },[])

  useEffect(() => {
    if ((gradientStartColor && gradientEndColor) || (element.style.backgroundImage && element.style.backgroundImage !== 'initial')) {
      if (!gradientStartColor || !gradientEndColor) {
        let parsedGradient = element.style.backgroundImage.match(/rgb\([^()]*\)|#\w+/g)
        setGradientStartColor(parsedGradient[0])
        setGradientEndColor(parsedGradient[1])
      }
      element.style.backgroundImage = `linear-gradient(${gradientDegree} ${gradientStartColor}, ${gradientEndColor})`
    }

  }, [gradientEndColor, gradientStartColor, gradientDegree])

  return (
    <div draggable="true" onDrag={handlePreviewDrag} style={modalStyle} className="color-edit-modal">
      <ModalCloseButton click={setShowColorEditModal} />
      <SketchPicker color='#000000' onChangeComplete={handleChangeComplete} />
      { whichStyleRef.current === 'color' ?
        <span onClick={() => setWhichStyle('color')} style={{"color": "red"}}>color</span> :
        <span onClick={() => setWhichStyle('color')} style={{"color": "black"}}>color</span> 
      }
      | 
      { whichStyleRef.current === 'borderColor' ?
        <span onClick={() => setWhichStyle('borderColor')} style={{"color": "red"}}>border-color</span> :
        <span onClick={() => setWhichStyle('borderColor')} style={{"color": "black"}}>border-color</span> 
      }
      | 
      { whichStyleRef.current === 'backgroundColor' ?
        <span onClick={() => setWhichStyle('backgroundColor')} style={{"color": "red"}}>background-color</span> :
        <span onClick={() => setWhichStyle('backgroundColor')} style={{"color": "black"}}>backgound-color</span> 
      }
      <br />
      <button className="smash-button" onClick={setTransparentBackground}>Transparent Background</button>
      <br />
      <button className="smash-button" onClick={() => setGradientStartColor(currentColorRef.current)} style={{"background":`${gradientStartColor}`}}>Set Gradient Start Color</button>
      <button className="smash-button" onClick={() => setGradientEndColor(currentColorRef.current)} style={{"background":`${gradientEndColor}`}}>Set Gradient End Color</button>
      <br />
      <NumberScroll start="180" suffix="deg," handleSet={handleSet}/>
      <br />
      <button className="smash-button" onClick={() => setDropShadowColor(currentColorRef.current)}>Drop Shadow Color</button>
      <hr />
      Favorite colors
      <div id="fav-color-container">
        {/* <div onClick={(e) => addFavoriteColor(e)} onMouseOver={(e) => handleElementMouseOver(e)} style={{'backgroundImage': 'transparent', 'height': '16px', 'width': '16px', 'cursor': 'pointer', 'outline': 'none', 'borderRadius': '3px', 'boxShadow': 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset'}}></div> */}
        <div onClick={(e) => addFavoriteColor(e)} style={{'backgroundImage': 'transparent', 'height': '16px', 'width': '16px', 'cursor': 'pointer', 'outline': 'none', 'borderRadius': '3px', 'boxShadow': 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset'}}></div>
        {favoriteColors}
      </div>

    </div>
  )
}

export default ColorEditModal