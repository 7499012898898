import React, {useState, useEffect, useRef} from 'react';
import cookie from 'react-cookies';
import './Canvas.scss';
import { dbCall, fileCall } from '../data';
import Elements from './Elements';
import NewProjectModal from './NewProjectModal';
import SelectEditModal from './SelectEditModal';
import ProjectsSelect from './ProjectsSelect';
import CanvasModal from './CanvasModal';
import DropShadowModal from './DropShadowModal';
import CropModal from './CropModal';
import ButtonEditModal from './ButtonEditModal';
import FontEditModal from './FontEditModal';
import StyleEditModal from './StyleEditModal';
import ColorEditModal from './ColorEditModal';
import ConfirmationModal from './ConfirmationModal';
import LinkModal from './LinkModal';
import HintModal from './HintModal';
import GridModal from './GridModal';
import RegisterModal from './RegisterModal';
import {insetArrayFromClipPath, copyStyle, deleteElement} from '../utils';

const Canvas = () => {
  const [project, setProject] = useState(window.location.href.split('/')[4] || '')
  // const [tab, setTab] = useState('elements')
  const [imageCount, _setImageCount] = useState(0);
  const [showSelectEditModal, setShowSelectEditModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [showNewProjectModal, setShowNewProjectModal] = useState(false)
  const [showCanvasModal, setShowCanvasModal] = useState(false)
  const [showDropShadowModal, setShowDropShadowModal] = useState(false)
  const [showCropModal, setShowCropModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showButtonEditModal, setShowButtonEditModal] = useState(false)
  const [showFontEditModal, setShowFontEditModal] = useState(false)
  const [showStyleEditModal, setShowStyleEditModal] = useState(false)
  const [showGridModal, setShowGridModal] = useState(false)
  const [showColorEditModal, setShowColorEditModal] = useState(false)
  const [showLinkModal, setShowLinkModal] = useState(false)
  const [previewImage, setPreviewImage] = useState(null) 
  const [rectStart, setRectStart] = useState([0,0])
  const [rectEnd, setRectEnd] = useState([0,0])
  const [drawNewRect, setDrawNewRect] = useState([0,0,0,0])
  // const [imageFile, setImageFile] = useState()
  // const [imageDataArr, setImageDataArr] = useState([])
  const [imageData, _setImageData] = useState([])
  const [imageArray, setImageArray] = useState([])
  const [page, _setPage] = useState(1)
  const [elementHoveredOver, _setElementHoveredOver] = useState();
  // const [imageHoveredOver, _setImageHoveredOver] = useState('');
  const [selectHoveredOver, _setSelectHoveredOver] = useState('');
  const [keyDown, _setKeyDown] = useState()
  // const [draggingImage, setDraggingImage] = useState();
  const [draggingElement, setDraggingElement] = useState();
  const [draggingSelect, setDraggingSelect] = useState();
  const [colorPicked, _setColorPicked] = useState('#000000')
  const [fontFamily, _setFontFamily] = useState();
  const [elementDraggedItem, _setElementDraggedItem] = useState();
  const [previewDraggedItem, _setPreviewDraggedItem] = useState();
  // const [canvasHeight, setCanvasHeight] = useState(800)
  const [presentationMode, _setPresentationMode] = useState(window.location.href.split('/').length > 4 ? true : false)
  const [password, setPassword] = useState()
  const [loginUsername, setLoginUsername] = useState(window.location.href.split('/')[3] || '')
  const [username, _setUsername] = useState(window.location.href.split('/')[3] || '')
  const [showHintModal, setShowHintModal] = useState(false)
  const [elementToDelete, setElementToDelete] = useState()
  const [projects, setProjects] = useState([])
  // const [ogWindowHeight, setOgWindowHeight] = useState(window.innerHeight)
  // const [ogWindowWidth, setOgWindowWidth] = useState(window.innerWidth)
  // const [canvasHeight, _setCanvasHeight] = useState();

  const pageRef = useRef(page)
  const setPage = data => {
    pageRef.current = data;
    _setPage(data)
  }

  const usernameRef = useRef(username)
  const setUsername = data => {
    usernameRef.current = data;
    _setUsername(data)
  }

  const presentationModeRef = useRef(presentationMode)
  const setPresentationMode = data => {
    presentationModeRef.current = data;
    _setPresentationMode(data)
  }

  const imageCountRef = useRef(imageCount)
  const setImageCount = data => {
    imageCountRef.current = data;
    _setImageCount(data)
  }

  const colorPickedRef = useRef(colorPicked)
  const setColorPicked = data => {
    colorPickedRef.current = data;
    _setColorPicked(data)
  }

  const elementDraggedItemRef = useRef(elementDraggedItem)
  const setElementDraggedItem = data => {
    elementDraggedItemRef.current = data;
    _setElementDraggedItem(data)
  }

  const previewDraggedItemRef = useRef(previewDraggedItem)
  const setPreviewDraggedItem = data => {
    previewDraggedItemRef.current = data;
    _setPreviewDraggedItem(data)
  }

  // const canvasHeightRef = useRef(canvasHeight)
  // const setCanvasHeight = data => {
  //   canvasHeightRef.current = data;
  //   _setCanvasHeight(data)
  // }

  const fontFamilyRef = useRef(fontFamily)
  const setFontFamily = data => {
    fontFamilyRef.current = data;
    _setFontFamily(data)
  }

  const elementHoveredOverRef = useRef(elementHoveredOver)
  const setElementHoveredOver = data => {
    elementHoveredOverRef.current = data;
    _setElementHoveredOver(data)
  }

  // const imageHoveredOverRef = useRef(imageHoveredOver)
  // const setImageHoveredOver = data => {
  //   imageHoveredOverRef.current = data;
  //   _setImageHoveredOver(data)
  // }
  const selectHoveredOverRef = useRef(selectHoveredOver)
  const setSelectHoveredOver = data => {
    selectHoveredOverRef.current = data;
    _setSelectHoveredOver(data)
  }
  // const imageDataRef = useRef(imageData)
  // const setImageData = data => {
  //   imageDataRef.current = data;
  //   _setImageData(data)
  // }
  const keyDownRef = useRef(keyDown)
  const setKeyDown = data => {
    keyDownRef.current = data;
    _setKeyDown(data)
  }
  // Function to prevent default behavior for drag over
  const handleDragOver = (e) => {
    e.preventDefault()
  }

  // put save into method and call it when elements are dropped and maybe when modals are closed
  const saveData = () => {
    let dataToSave = Array.from(document.getElementById("preview").getElementsByTagName("*")).map(element => element.outerHTML).join('')
    let params = {
      // 'data': document.getElementById("preview").outerHTML,
      'data': dataToSave,
      'username': username,
      'project': project
    }
    dbCall('/save_data', params).then(data => {
      if (data.message === 'success') {
        console.log("data saved")
      }
    })
  }

  const getElementsNew = ({elementStorage, attributeName, endpoint, target}) => {
    let params = {
      'username': usernameRef.current
    }
    dbCall(endpoint, params).then(data => {
      if (data.message === 'success') {
        let tempParent = document.createElement('div')
        tempParent.id = "tempParent"
        tempParent.innerHTML = data.html
        let cloneElement, newElement
        Array.from(tempParent.getElementsByTagName("*")).forEach(element => {
          cloneElement = document.createElement(element.tagName)
          cloneElement.setAttribute('name', attributeName)
          copyStyle(cloneElement, element.style)
          cloneElement.innerHTML = element.innerHTML
          cloneElement.onmouseover = (cloneElement) => handleElementMouseOver(cloneElement);
          cloneElement.onmouseout = (cloneElement) => handleElementMouseOut(cloneElement);
          cloneElement.onclick = (cloneElement) => handleElementOnClick(cloneElement);
          newElement = document.getElementById(target).appendChild(cloneElement);
          newElement.setAttribute('draggable', true)
          newElement.setAttribute('page', element.getAttribute('page'))
          newElement.ondrag = (newElement) => handleElementDrag(newElement)
          if (element.name !== 'file-box-element') {
            if (Number(element.getAttribute('page')) === page || elementStorage) {
              newElement.style.display = 'inline-block'
            } else {
              newElement.style.display = 'none'
            }
          }
          if (elementStorage) {
            newElement.removeAttribute('link')
            newElement.removeAttribute('page')
            // newElement.removeAttribute('name')
          }
        })
        tempParent.remove()
      }
    })
  }


  useEffect(() => {
    // might get rid of all window listeners
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)
    window.addEventListener("mousewheel", handleMouseWheel)
    document.getElementById('element-container').addEventListener("drag", handleElementDrag)
    // this is because useEffect runs twice in development
    // when in strictmode (set in index)
    // running this twice causes problems
    // if (!initialized.current && username) { // probably just until login
    // if (username) { // probably just until login
      // getElements(true)
      // getElementsNew({elementStorage:true, attributeName:'file-box-element', endpoint:'/get_elements', target:'file-box'})
      // getElementsNew({elementStorage:true, attributeName:'add-color-box', endpoint:'/get_colors', target:'fav-color-container'})
      // getColors()
      // initialized.current = true
    // }
    if (!username && cookie.loadAll().smashsession && cookie.loadAll().smashuser) {
      let params = {
        sessionValue: cookie.loadAll().smashsession,
        username: cookie.loadAll().smashuser
      }
      dbCall('/auth', params).then(data => {
        console.log("data is ", data)
        if (data.message === 'success') {
          setUsername(data.username)
        }
      })
    }
    return () => {
      window.removeEventListener('mousewheel', handleMouseWheel)
      window.removeEventListener('keyup', remKeyUp)
      // document.getElementById('element-container').removeEventListener('drag', remElementDrag)
    }
  }, [username])

  useEffect(() => {
    getData()
    if (document.getElementById('project-chooser')) {
      document.getElementById('project-chooser').value = project
    }
    if (project && !presentationMode) {
      document.getElementById("preview").style.left = "240px"
      document.getElementById('canvas-top-left').style.display = 'block'
    }
  }, [project])

  useEffect(() => {
    getProjects()
    // if you reload should you remember your project?
  },[username])

  const getProjects = () => {
    if (!usernameRef.current) { return; }
    let params = {
      'username': usernameRef.current,
      'project': project
    }
    dbCall('/get_projects', params).then(data => {
      if (data.message === 'success') {
        setProjects(data.projects)
      }
    })
  }

  const getData = () => {
    if (!username || !project) { return; }
    // this kills all the eventhandling
    let params = {
      'username': username,
      'project': project
    }
    if (project === 'new project') {
      setShowNewProjectModal(true);
      return;
    }
    dbCall('/get_html', params).then(data => {
      if (data.message === 'success') {
        document.getElementById('preview').innerHTML = null
        let tempParent = document.createElement('div')
        tempParent.id = "tempParent"
        tempParent.innerHTML = data.html
        let cloneElement, newElement
        Array.from(tempParent.getElementsByTagName("*")).forEach(element => {
          cloneElement = document.createElement(element.tagName)
          cloneElement.setAttribute('name', 'added-element')
          copyStyle(cloneElement, element.style)
          cloneElement.style.display = element.style.display;
          cloneElement.setAttribute('page', element.page)
          element.getAttribute('link') && cloneElement.setAttribute('link', element.getAttribute('link'))
          cloneElement.innerHTML = element.innerHTML
          cloneElement.onmouseover = (cloneElement) => handleElementMouseOver(cloneElement);
          cloneElement.onmouseout = (cloneElement) => handleElementMouseOut(cloneElement);
          cloneElement.onclick = (cloneElement) => handleElementOnClick(cloneElement);
          newElement = document.getElementById("preview").appendChild(cloneElement);
          newElement.setAttribute('draggable', true)
          newElement.setAttribute('page', element.getAttribute('page'))
          element.getAttribute('src') && newElement.setAttribute('src', element.getAttribute('src'))
          if (Number(element.getAttribute('page')) === page) {
            newElement.style.display = 'inline-block'
          } else {
            newElement.style.display = 'none'
          }
        })
        tempParent.remove()
      }
    })
  }

  const remElementDrag = () => {
    console.log("?")
  }

  const handleElementDrag = (e) => {
    setElementDraggedItem(e)
  }

  const handlePreviewDrag = (e) => {
    setShowHintModal(false)
    setPreviewDraggedItem(e.target)
  }

  const getDistance = (x1, x2, y1, y2) => {
    return Math.pow(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2), 0.5)
  }

  const posToPreviewX = (x) => {
    return x - Number(document.getElementById('preview').style.left.replace(/\D/g,''))
  }

  const posToPreviewY = (y) => {
    return y - Number(document.getElementById('preview').style.top.replace(/\D/g,''))
  }

  const snapToGrid = (element, event) => {
    // this could be smarter - maybe make it smarter
    let distance = 10000;
    let snapTop, snapLeft;

    // search through each dot
    // Array.from(document.getElementsByClassName('dot-class')).forEach(dot => {
    //   let dotRect = dot.getBoundingClientRect()
    //   if (getDistance(dotRect.left, event.pageX, dotRect.top, event.pageY) < distance) {
    //     snapTop = dotRect.top;
    //     snapLeft = dotRect.left;
    //     distance = getDistance(dotRect.left, event.pageX, dotRect.top, event.pageY) 
    //   }
    // })
    const allDots = Array.from(document.getElementsByClassName('dot-class'))

    // get last dot to know how many rows and columns
    let lastDot = allDots[allDots.length - 1]
    let numRows = Number(lastDot.id.split('_')[1]) + 1
    let numCols = Number(lastDot.id.split('_')[2]) + 1

    // find closest row and closest column simple loop
    distance = 10000
    let droppedX = posToPreviewX(event.pageX)
    let droppedY = posToPreviewY(event.pageY)
    var closestRowDotRect, closestColDotRect
    for (let i=0; i<numRows; i+=1) {
      let thisDot = allDots[i*numCols]
      let thisDotRect = thisDot.getBoundingClientRect()
      if (getDistance(posToPreviewX(thisDotRect.left), droppedX, posToPreviewY(thisDotRect.top), droppedY) < distance) {
        distance = getDistance(
          posToPreviewX(thisDotRect.left),
          // event.pageX - Number(document.getElementById.style.left.replace(/\D/g,'')),
          droppedX,
          posToPreviewY(thisDotRect.top),
          droppedY)
        closestRowDotRect = thisDotRect
      }
    }
    distance = 10000;
    for (let i=0; i<numCols; i+=1) {
      let thisDot = allDots[i]
      let thisDotRect = thisDot.getBoundingClientRect()
      if (getDistance(posToPreviewX(thisDotRect.left), droppedX, posToPreviewY(thisDotRect.top), droppedY) < distance) {
        distance = getDistance(posToPreviewX(thisDotRect.left), droppedX, posToPreviewY(thisDotRect.top), droppedY) 
        closestColDotRect = thisDotRect
      }
    }
    snapTop = posToPreviewY(closestRowDotRect.top)
    snapLeft = posToPreviewX(closestColDotRect.left)
    element.style.top = snapTop.toString() + 'px'
    element.style.left = snapLeft.toString() + 'px'
  }

  const handlePreviewDrop = (e) => {
    if (!username) {
      alert("You need to log in register to drop that");
      return;
    }
    e.preventDefault()
    if (!elementDraggedItemRef.current && !e.dataTransfer.files[0]) {
      if (previewDraggedItemRef.current.id.substr(-5) === 'modal') {
        previewDraggedItemRef.current.style.top = (e.pageY).toString() + 'px'
        previewDraggedItemRef.current.style.left = (e.pageX).toString() + 'px'
      } else {
        previewDraggedItemRef.current.style.top = posToPreviewY(e.pageY).toString() + 'px'
        previewDraggedItemRef.current.style.left = posToPreviewX(e.pageX).toString() + 'px'
      }
      if (showGridModal) { snapToGrid(previewDraggedItemRef.current, e) }
      setPreviewDraggedItem(null)
      return
    }
    let element;
    const file = e.dataTransfer.files[0]
    if (file) {
      setImageCount(document.getElementById("preview").getElementsByTagName('img').length)
      element = document.createElement('img')
      let params = {
        'image': file,
        'image_name': `image_${imageCountRef.current}.jpg`,
        'username': username,
        'project': project
      }
      dbCall('/upload_image', params).then(data => {
        if (data.message === 'success') {
          element.setAttribute('src', `/users/${username}/${project}/images/image_${imageCountRef.current}.jpg?t=` + Date.now().toString())
          saveData()
        }
      })
    } else {
      element = document.createElement(elementDraggedItemRef.current.target.id || elementDraggedItemRef.current.target.tagName)
    }
    if (elementDraggedItemRef.current && elementDraggedItemRef.current.target.style) {
      copyStyle(element, elementDraggedItemRef.current.target.style)
    }
    element.name = "added-element";
    element.style.position = 'absolute';
    element.style.top = posToPreviewY((e.pageY)).toString() + 'px';
    // element.style.top = snapTop.toString() + 'px'
    element.style.left = posToPreviewX((e.pageX)).toString() + 'px'
    element.style.zIndex = 1
    // element.style.left = snapLeft.toString() + 'px'
    element.setAttribute('page', pageRef.current)
    if (showGridModal) { snapToGrid(element, e) }
    element.onmouseover = (element) => handleElementMouseOver(element);
    element.onmouseout = (element) => handleElementMouseOut(element);
    element.onclick = (element) => handleElementOnClick(element);
    element.innerHTML = elementDraggedItemRef.current && elementDraggedItemRef.current.target.innerHTML || 'Content'
    element.defaultValue = elementDraggedItemRef.current && elementDraggedItemRef.current.target.value && elementDraggedItemRef.current.target.value
    let newElement = document.getElementById("preview").appendChild(element);
    newElement.setAttribute('draggable', true)
    setElementDraggedItem(null)
    saveData();
  }

  const handleElementOnClick = (element) => {
    if (element.target.getAttribute('link')) {
      setPage(Number(element.target.getAttribute('link')))
    }
  }

  const handleKeyDown = (e) => {
    if (presentationModeRef.current) { return }
    if (e.key !== 'Shift') {
      setKeyDown(e.key)
    }
    if (e.key === 'e' && elementHoveredOverRef.current) {
      if (elementHoveredOverRef.current.tagName === 'SELECT') { setShowSelectEditModal(true) }
      if (elementHoveredOverRef.current.tagName === 'BUTTON') { setShowButtonEditModal(true) }
      if (elementHoveredOverRef.current.tagName === 'DIV') { setShowButtonEditModal(true) }
    }
    if (e.key === 'l' && elementHoveredOverRef.current) {
      setShowLinkModal(true)
    } 
    if (e.key === 's' && elementHoveredOverRef.current) {
      setShowStyleEditModal(true)
    } 
    if (e.key === 'c' && elementHoveredOverRef.current) {
      setShowColorEditModal(true)
    } 
    if (e.key === 'f' && elementHoveredOverRef.current) {
      setShowFontEditModal(true)
    } 
    if (e.key === 'd' && elementHoveredOverRef.current) {
      setShowDropShadowModal(true)
    } 
    if (e.key === 'x' && elementHoveredOverRef.current) {
      setShowCropModal(true)
    } 
    if (e.key === 'Backspace' && elementHoveredOverRef.current) {
      // send the confirmation modal a function
      // confirmation modal can accept any function
      // send props to call that function with
      // confirmation modal can accept any props
      // in this case the function is delete element
      // and the element is the prop
      setElementToDelete(elementHoveredOverRef.current)
      setShowConfirmationModal(true)
      // if (elementHoveredOverRef.current.tagName === 'IMG') {
      //   let params = {
      //     'image_name': 'image_' + numberFromImageFile(elementHoveredOverRef.current.src) + '.jpg',
      //     'username': usernameRef.current,
      //     'project': project
      //   }
      //   dbCall('/delete_image', params).then(data => {
      //     if (data.message === 'success') {
      //       console.log("image deleted")
      //     }
      //   })
      // }
      // elementHoveredOverRef.current.remove()
      // if (elementHoveredOverRef.current.getAttribute('name') === 'added-color-box') {
      //   let addedBoxes = Array.from(document.getElementById("fav-color-container").children)
      //                         .filter(element => element.getAttribute('name') === 'added-color-box')
      //                         .map(element => element.outerHTML)
      //                         .join('')
      //   overwriteColorsInFile(username, addedBoxes)
      // }
      setElementHoveredOver('')
    }
    if (!elementHoveredOverRef.current) {
      if (e.key === 's' && e.target.tagName === 'BODY') {
        if (document.getElementById('button-modal')) { return; }
        setShowCanvasModal(true)
      }
    }
  }

  const handleKeyUp = (e) => {
    setKeyDown(null)
  }

  const remMouseWheel = () => {
    console.log("what am I supposed to do here")
  }

  const remKeyUp = () => {
    console.log("what am I supposed to do here")
  }

  const changeMatchingElementInArrayOfHashes = (source, matchKey, matchValue, key, value) => {
    let thisIndex = source.findIndex((element) => 
      element[matchKey] === matchValue
    )
    let replacedElement = source[thisIndex]
    replacedElement[key] = value
    let replacement = source
                .slice(0, thisIndex).concat(replacedElement)
                .concat(source.slice(thisIndex +1))
    return replacement
  }

  const stripColor = (str) => {
    return str.replace(/rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/g, '')
  }

  const newValueForPx = (element, wheelDelta, attribute, defaultValue, step) => {
    let existingSize = element.style[attribute] ? 
    Number(stripColor(element.style[attribute]).replace(/\D/g,'')) : 
    defaultValue;
    if (wheelDelta < 0) {
      return (existingSize + step).toString() + 'px';
    } else {
      return (existingSize - step).toString() + 'px';
    }
  }

  const handleMouseWheel = (e) => {
    if (presentationModeRef.current) { return }
    let newClipPath;
    var newClipTop
    let newClipLeft
    let newClipRight
    let insetArray;
    const mainCanvas = document.getElementById("preview")

    if (elementHoveredOverRef.current) {
      let _element = elementHoveredOverRef.current
      if (_element.name !== 'added-element') { return; }
      switch(keyDownRef.current) {
        case "F":
          _element.style.fontSize = newValueForPx(_element, e.wheelDelta, "fontSize", 13, 1)
          break;
        case "R":
          _element.style.borderRadius = newValueForPx(_element, e.wheelDelta, "borderRadius", 0, 1)
          break;
        case "B":
          _element.style.border = newValueForPx(_element, e.wheelDelta, 'border', 1, 1) + ' solid'
          break;
        case "f":
          _element.style.fontFamily = fontFamilyRef.current
          break;
        case "g":
          _element.style.color = colorPickedRef.current
          break;
        case "h":
          _element.style.borderColor = colorPickedRef.current
          break;
        case "b":
          _element.style.backgroundColor = colorPickedRef.current
          break;
        case "s":
          _element.style.letterSpacing = newValueForPx(_element, e.wheelDelta, 'letterSpacing', 1, 1);
          break;
        case "z":
          let existingZ = _element.style.zIndex ?
            Number(_element.style.zIndex) : 1
          let newZ
          if (e.wheelDelta < 0) {
            newZ = existingZ + 1
          } else {
            newZ = existingZ - 1
          }
          _element.style.zIndex = newZ
          break;
        case "O":
          let existingOpacity = _element.style.opacity ? 
            Number(_element.style.opacity) : 1
          let newOpacity
          if (e.wheelDelta < 0) {
            newOpacity = existingOpacity * .9
          } else {
            newOpacity = existingOpacity / .9
          }
          _element.style.opacity = newOpacity
          break;
        case "M": 
          insetArray = insetArrayFromClipPath(_element.style.clipPath)
          let existingClipBottom = insetArray[2]
          let newClipBottom
          if (e.wheelDelta < 0) {
            newClipBottom = existingClipBottom + 5
          } else {
            newClipBottom = existingClipBottom >= 5 ? existingClipBottom - 5 : 0
          }
          newClipPath = "inset(" + insetArray[0] + "px " + insetArray[1] + "px " + newClipBottom.toString() + "px " + insetArray[3] + "px)"
          _element.style.clipPath = newClipPath
          break;
        case "I":
          insetArray = insetArrayFromClipPath(_element.style.clipPath)
          let existingClipTop = insetArray[0]
          if (e.wheelDelta < 0) {
            newClipTop = existingClipTop >= 5 ? existingClipTop - 5 : 0
          } else {
            newClipTop = existingClipTop + 5
          }
          newClipPath = "inset(" + newClipTop + "px " + insetArray[1] + "px " + insetArray[2] + "px " + insetArray[3] + "px)"
            _element.style.clipPath = newClipPath
          break;
        case "J":
          insetArray = insetArrayFromClipPath(_element.style.clipPath)
          let existingClipLeft = insetArray[3]
          if (e.wheelDelta < 0) {
            newClipLeft = existingClipLeft + 5
          } else {
            newClipLeft = existingClipLeft >= 5 ? existingClipLeft - 5 : 0
          }
          newClipPath = "inset(" + insetArray[0] + "px " + insetArray[1] + "px " + insetArray[2] + "px " + newClipLeft + "px)"
          _element.style.clipPath = newClipPath
          break;
        case "K":
          insetArray = insetArrayFromClipPath(_element.style.clipPath)
          let existingClipRight = insetArray[1]
          if (e.wheelDelta < 0) {
            newClipRight = existingClipRight + 5
          } else {
            newClipRight = existingClipRight >= 5 ? existingClipRight - 5 : 0
          }
          newClipPath = "inset(" + insetArray[0] + "px " + newClipRight + "px " + insetArray[2] + "px " + insetArray[3] + "px)"
          _element.style.clipPath = newClipPath
          break;
        case "S":
          // just doing drop shadow in filter for now - will need changes
          // if using other filters
          let existingShadow = _element.style.filter ?
            (
              _element.style.filter.split('px').length > 2 ?
              Number(_element.style.filter.split('px')[1].trim()) :
              Number(_element.style.filter.replace(/\D/g,''))
            )
            :
            0
          let newShadow
          if (e.wheelDelta < 0) {
            newShadow = existingShadow + 1
          } else {
            newShadow = existingShadow >= 1 ? existingShadow - 1 : 0
          }
          const newFilter = "drop-shadow(" + newShadow.toString() + "px " + newShadow.toString() + "px 2px black)"
            _element.style.filter = newFilter
          break;
        default:
          _element.style.width = newValueForPx(_element, e.wheelDelta, 'width', 150, 5)
          break;
      }
    }

    if (selectHoveredOverRef.current) {
      let _select = selectHoveredOverRef.current
      switch(keyDownRef.current) {
        case "F":
          _select.style.fontSize = newValueForPx(_select, e.wheelDelta, "fontSize", 13, 1)
          break;
        case "R":
          _select.style.borderRadius = newValueForPx(_select, e.wheelDelta, "borderRadius", 0, 1)
          break;
        case "B":
          _select.style.border = newValueForPx(_select, e.wheelDelta, 'border', 1, 1) + ' solid'
          break;
        default:
          _select.style.width = newValueForPx(_select, e.wheelDelta, 'width', 50, 5)
          break;
      }
    }
  }

  const draggedElement = (target) => {
    setDraggingElement(target)
  }

  // const draggedInput = (target) => {
  //   setDraggingInput(target)
  //   // I can do the same thing with input elements
  // }

  const draggedSelect = (target) => {
    setDraggingSelect(target)
  }

  const mousedOver = (target) => {
    // setImageHoveredOver(target)
    document.getElementById("hint").style.backgroundColor = 'white'
    document.getElementById("hint").innerText = target 
    document.getElementById("hint").style.top = (imageData.find(imageDatum => imageDatum.name === target).startY).toString() + 'px'
    document.getElementById("hint").style.left = (imageData.find(imageDatum => imageDatum.name === target).startX).toString() + 'px'
  }

  const mousedAway = (target) => {
    // setImageHoveredOver('')
    document.getElementById("hint").innerText = ""
  }

  const handleElementMouseOver = (e) => {
    setElementHoveredOver(e.target)
    if (presentationModeRef.current || e.target.name !== 'added-element') {return}
    setShowHintModal(true)
  }

  const handleElementMouseOut = (e) => {
    setElementHoveredOver('')
    setShowHintModal(false)
  }

  const handleSelectMouseOver = (e) => {
    setSelectHoveredOver(e.target)
  }

  const handleSelectMouseOut = (e) => {
    setSelectHoveredOver('')
  }

  useEffect(() => {
    var addedElements = document.getElementsByName("added-element")
    for (const addedElement of addedElements) {
      if (Number(addedElement.getAttribute('page')) !== page) {
        addedElement.style.display = 'none';
      } else {
        addedElement.style.display = 'inline-block';
        if (addedElement.tagName === 'IMG') {
          addedElement.setAttribute('src', `/users/${username}/${project}/images/` + addedElement.src.split('/images/')[1].split('?')[0] + '?t=' + new Date().getTime())
        }
      }
    }
    var selectElements = document.getElementsByName("select-element")
    for (const selectElement of selectElements) {
      if (Number(selectElement.getAttribute('page')) !== page) {
        selectElement.style.display = 'none';
      } else {
        selectElement.style.display = 'inline-block';
      }
    }
  }, [page])

  const startRect = event => {
    // get bounding rect for the canvas
    const canvas = document.getElementById("preview");
    const boundingCanvas = canvas.getBoundingClientRect(); 
    setRectStart([event.clientX, event.clientY])
  }

  const endRect = event => {
    setRectEnd([event.clientX, event.clientY])
    setDrawNewRect([rectStart[0], rectStart[1], event.clientX - rectStart[0], event.clientY - rectStart[1]])
  }

  const previewStyle = {
    position: 'absolute',
    top: drawNewRect[1],
    left: drawNewRect[0] 
  }

  const pageIncrement = () => {
    setPage(page + 1);
  }

  const pageDecrement = () => {
    setPage(page - 1);
  }

  const highestPageNumber = () => {
    return Math.max(...Array.from(document.getElementById('preview').getElementsByTagName('*')).map(element => 
      Number(element.getAttribute('page'))
    ))
  }

  const imagesOnPage = (p) => {
    // return imageDataRef.current.filter(imageDatum => 
      // imageDatum.pageNumber === p   
    // )
    return Array.from(
        document.getElementById('preview').getElementsByTagName('img')
      ).filter(img => {
        return img.getAttribute('page') === p.toString()
      })
  }
  
  const clonePage = () => {
    let nextPage = highestPageNumber() + 1
    let onPage = imagesOnPage(page)
    let numImages = document.getElementById('preview').getElementsByTagName('img').length
    let copyImages = []
    let newName = ''
    let cloneElement;
    let newElement;
    // copy image element with new page and src
    let newImages = onPage.forEach((image, i) => {
      cloneElement = document.createElement('img')
      newName = `image_${numImages + i}.jpg`
      copyImages.push({ old_name: image.src.split('/images/')[1].split('?')[0], new_name: newName})
      cloneElement.src = `/users/${username}/${project}/images/${newName}`
      cloneElement.setAttribute('name', 'added-element')
      copyStyle(cloneElement, image.style)
      cloneElement.style.display = 'none';
      cloneElement.setAttribute('page', nextPage)
      cloneElement.onmouseover = (cloneElement) => handleElementMouseOver(cloneElement);
      cloneElement.onmouseout = (cloneElement) => handleElementMouseOut(cloneElement);
      cloneElement.onclick = (cloneElement) => handleElementOnClick(cloneElement);
      newElement = document.getElementById("preview").appendChild(cloneElement);
      newElement.setAttribute('draggable', true)
    })
    // // setImageData(imageData.concat(newImages))
    if (onPage.length > 0) {
      let params = {
        'image_names': JSON.stringify(copyImages),
        'username': username,
        'project': project
      }
      dbCall('/copy_images', params).then(data => {
        if (data.message === 'success') {
          console.log("images copied")
        }
      })
    }
    // let addElements = document.getElementById('preview').getElementsByName('added-element')
    let addElements = document.getElementsByName('added-element')
    Array.from(addElements).forEach(element => {
      if (element.tagName === 'IMG') { return }
      if (Number(element.getAttribute('page')) !== page) { return }
      cloneElement = document.createElement(element.tagName)
      cloneElement.setAttribute('name', 'added-element')
      copyStyle(cloneElement, element.style)
      cloneElement.style.display = 'none';
      cloneElement.setAttribute('page', nextPage)
      cloneElement.innerHTML = element.innerHTML
      cloneElement.onmouseover = (cloneElement) => handleElementMouseOver(cloneElement);
      cloneElement.onmouseout = (cloneElement) => handleElementMouseOut(cloneElement);
      cloneElement.onclick = (cloneElement) => handleElementOnClick(cloneElement);
      newElement = document.getElementById("preview").appendChild(cloneElement);
      newElement.setAttribute('draggable', true)
    })
  }

  // perhaps add fields that you can type in
  // could generate file that could be sent that would extract
  // and work

  const handleChangeComplete = (color) => {
    setColorPicked(color.hex)
  }

  const handleFileBoxDrop = (e) => {
    e.preventDefault();
    if (!previewDraggedItemRef.current) { 
      return
    }
    const element = document.createElement(previewDraggedItemRef.current.tagName);
    let newElement = document.getElementById("file-box").appendChild(element);
    newElement.innerHTML = previewDraggedItemRef.current.innerHTML
    previewDraggedItemRef.current.getAttribute('value') && newElement.setAttribute('value', previewDraggedItemRef.current.getAttribute('value'))
    copyStyle(newElement, previewDraggedItemRef.current.style)
    newElement.style.position = ''
    newElement.style.top = ''
    newElement.style.left = ''
    newElement.setAttribute('draggable', 'true')
    newElement.setAttribute('name', 'file-box-element')
    newElement.ondrag = (newElement) => handleElementDrag(newElement)
    let dataToSave = newElement.outerHTML
    let params = {
      // 'data': document.getElementById("preview").outerHTML,
      'data': dataToSave,
      'username': username,
    }
    dbCall('/save_element', params).then(data => {
      if (data.message === 'success') {
        console.log("element saved")
      }
    })
  }

  const setCanvasElementsDraggable = (bool) => {
    Array.from(document.getElementById('preview').getElementsByTagName('*')).forEach(element => {
      element.setAttribute('draggable', bool)
    })
  }

  const handlePresentationMode = () => {
    // document.getElementById("exit-presentation-mode").style.display = 'inline-block'
    document.getElementById("canvas-top-left").style.display = 'none'
    document.getElementById("preview").style.left = '0px'
    setCanvasElementsDraggable(false)
    setPresentationMode(true)
  }

  const handleFileBoxDragOver = (e) => {
    e.preventDefault();
  }

  const exitPresentationMode = () => {
    // document.getElementById("exit-presentation-mode").style.display = 'none'
    document.getElementById("canvas-top-left").style.display = 'flex'
    document.getElementById("preview").style.left = '240px'
    setCanvasElementsDraggable(true)
    setPresentationMode(false)
  }

  const handleLogin = (e) => {
    let params = {
      username: loginUsername,
      password: password
    }
    dbCall('/login', params).then(data => {
      console.log("data is ", data)
      if (data.message === 'success') {
        cookie.save('smashuser', loginUsername, { path: '/'})
        cookie.save('smashsession', data.sessionValue, { path: '/'})
        setUsername(loginUsername)
        getProjects(loginUsername)
      }
    })
  }

  const handleLoginUsernameChange = (e) => {
    setLoginUsername(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleProjectSelectChange = (e) => {
    setProject(e.target.value)
    if (!document.getElementById('file-box').innerHTML) {
      getElementsNew({elementStorage:true, attributeName:'file-box-element', endpoint:'/get_elements', target:'file-box'})
    }
  }

  const logout = () => {
    setUsername('')
    setProject('')
    document.getElementById('canvas-top-left').style.display = 'none' 
      document.getElementById("preview").style.left = "0px"
    document.getElementById('preview').innerHTML = null
    document.getElementById('file-box').innerHTML = null
    cookie.remove('smashuser', { path: '/'})
    cookie.remove('smashsession', { path: '/'})
  }

  const handleRegister = () => {
    console.log("register")
    setShowRegisterModal(true)
  }

  const downloadProject = async (project) => {
    let params = {
      username: username,
      project: project
    }
    const imageFileNames = await dbCall('/get_images_names', params)
    const imageDataPromiseArr = imageFileNames.map(imageName => {
      return new Promise( async (resolve, reject) => {
        params['image_name'] = imageName;
        resolve(await fileCall('/download_image', params))
      })
    })
    const imageDataArr = await Promise.all(imageDataPromiseArr)
    const imageFileReaderArr = imageDataArr.map(imageBlob => {
      return new Promise ( async (resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(imageBlob)
        reader.onloadend = async () => {
          resolve(reader.result)
        }
      })
    })
    const resolvedFileReaderArr = await Promise.all(imageFileReaderArr)
    const imageNameDataObj = imageFileNames.reduce(
      (sum, key, index) => Object.assign(sum, { [key]: resolvedFileReaderArr[index]}),
      {}
    )

    const htmlData = await fileCall('/download_project', params)
    const text = await htmlData.text()
    const someJS = `
        window.onload = () => addAlerts();
        var imgData = JSON.parse('${JSON.stringify(imageNameDataObj)}')
        var page = 1;
        const setDisplay = (e) => e.style.display = ((e.getAttribute('page') == page) ? 'block' : 'none');
        const setImageSrc = () => {
          Array.from(document.getElementsByTagName('img')).forEach(img => {
            img.src = imgData[img.src.split('/')[7].split('?')[0]]
          })
        }
        const setAllDisplays = () => Array.from(
                                      document.getElementsByTagName('BODY')[0]
                                        .children).forEach(element => setDisplay(element));
        const addAlerts = () => {
          setAllDisplays();
          setImageSrc();
          Array.from(document.getElementsByTagName('body')[0].children).forEach(element =>
            element.onclick = () => {
              if (element.getAttribute('link')) { page = element.getAttribute('link'); }
              setAllDisplays()
            }
          )
        }
      `
    const newBlob = new Blob([`<html><head><script>${someJS}</script></head><body>` + text + "</body></html>"], {type: 'text/plain'} )

    const filename = "projectdownload.html"
    const aTag = document.createElement('a')
    aTag.href = URL.createObjectURL(newBlob)
    aTag.download = filename
    aTag.click()
  }

  return  (
    <div className="canvas">
      {window.location.href.split('/').length <= 4 &&
      <div className="header" id="header">
        <div id="header-top-line">
          <div id="header-top-line-spacer"></div>
          <div id="header-title">SmashFrame</div>
          <span id="login-area">
            { username ?
              <div id="welcome-user">hello {username} <button className="smash-button" type="button" onClick={logout}>log out</button></div> :
              <div>
                <input type="text" onChange={handleLoginUsernameChange} id="login-username" placeholder="username"></input>
                <input type="password" onChange={handlePasswordChange} id="login-password" placeholder="password"></input>
                <button type="button" id="login-button" onClick={handleLogin} >log in</button>
                <button type="button" id="register-button" onClick={handleRegister}>register</button>
              </div>
            }
          </span>
        </div>
        <div id="header-bottom-line">
          <div>pg {page}</div> 
          &nbsp;
          <img src="/up.svg" width="15px" onClick={pageIncrement} /> 
          <img src="/down.svg" name="down-button"  width="15px" onClick={pageDecrement} />
          { username && 
            <>
              { !presentationMode ? 
                <button onClick={handlePresentationMode} className="smash-button" id="go-into-presentation-mode">go into presentation mode</button>
                :
                <button onClick={exitPresentationMode} className="smash-button" id="exit-presentation-mode">exit presentation mode</button>
              }
              { project &&
                <div style={{fontSize: '11px'}}>
                <a href={`./${username}/${project}`}>send-a-link</a> <br />
                <button className="smash-button" style={{fontSize: "10px", height: '18px'}} onClick={() => downloadProject(project)}>download-project</button>
                </div>
              }
              &nbsp; &nbsp;<button className="smash-button" onClick={clonePage}>clone this page</button>
              &nbsp; &nbsp;<button className="smash-button" onClick={getData}>get data</button>
              &nbsp; &nbsp;<ProjectsSelect projects={projects} handleChange={handleProjectSelectChange} />
              &nbsp; &nbsp;<button className="smash-button" onClick={saveData}>save data</button>
            </>
          }
        </div>
       </div>
      }
      <div className="canvas-top" id="canvas-top">
        <div className="canvas-top-left" style={{display:"none"}} id="canvas-top-left">
          <div id="element-container" onDrag={handleElementDrag}><Elements /></div>
          <hr />
          <h4>Element Storage</h4>
          <div id="file-box" onDragOver={handleFileBoxDragOver} onDrop={handleFileBoxDrop}>
          </div>
        </div>
        <div
          className="canvas-main"
          id="preview"
          onDragOver={handleDragOver}
          onDrag={handlePreviewDrag}
          onDrop={handlePreviewDrop}
          onMouseDown={startRect}
          onMouseUp={endRect}
          // onKeyDown={handleKeyDown}
          // onDoubleClick={handleDoubleClick}
          // style={`{"minHeight": "${canvasHeight}px"}`}
          style={{"position": "absolute", "top": "77px", "minHeight": "800px", "minWidth": "700px"}}
        >
          { showNewProjectModal && 
            <NewProjectModal
              top={10}
              left={300}
              username={username}
              setProject={setProject}
              setShowNewProjectModal={setShowNewProjectModal}
              projects={projects}
            />
          }

        </div>
        <div>
          { showHintModal && 
            <HintModal
              setShowHintModal={setShowHintModal}
              selectProp={elementHoveredOverRef.current}
              top={100}
              left={100}
            />
          }
          <div id="imageWrapper"></div>
          { previewImage && (<img src={previewImage} style={previewStyle} />) }
          { imageArray }
          {/* <img src="/Downloads/firstImage.jpg" style={previewStyle} /> */}
          {/* <svg className="svg-window">
            <rect x={drawNewRect[0]} y={drawNewRect[1]} width={drawNewRect[2]} height={drawNewRect[3]} stroke="black" strokeWidth="5" fill="none" />
          </svg> */}
          { showSelectEditModal && 
            <SelectEditModal
              setShowSelectEditModal={setShowSelectEditModal}
              selectProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showLinkModal &&
            <LinkModal
              setShowLinkModal={setShowLinkModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showStyleEditModal &&
            <StyleEditModal
              setShowStyleEditModal={setShowStyleEditModal}
              elementProp={elementHoveredOverRef.current}
              // top={Number(elementHoveredOverRef.current.style.top.replace(/\D/g,''))}
              top={100}
              // left={Number(elementHoveredOverRef.current.style.left.replace(/\D/g,''))}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showColorEditModal &&
            <ColorEditModal
              setShowColorEditModal={setShowColorEditModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              username={username}
              handleElementMouseOver={handleElementMouseOver}
              handleKeyDown={handleKeyDown}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showFontEditModal &&
            <FontEditModal
              setShowFontEditModal={setShowFontEditModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showButtonEditModal &&
            <ButtonEditModal
              setShowButtonEditModal={setShowButtonEditModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showDropShadowModal &&
            <DropShadowModal
              setShowDropShadowModal={setShowDropShadowModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showCropModal &&
            <CropModal
              setShowCropModal={setShowCropModal}
              elementProp={elementHoveredOverRef.current}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
            />
          }
          { showConfirmationModal &&
            <ConfirmationModal
              setShowConfirmationModal={setShowConfirmationModal}
              top={100}
              left={100}
              handlePreviewDrag={handlePreviewDrag}
              callBack={deleteElement}
              props={{element: elementToDelete, username: username, project: project}}
              message="Do you really want to delete that element?"
            />
          }
          { showCanvasModal &&
            <CanvasModal
              setShowCanvasModal={setShowCanvasModal}
              setShowGridModal={setShowGridModal}
              handlePreviewDrag={handlePreviewDrag}
              top={100}
              left={100}
            />
          }
          { showGridModal && 
            <GridModal
              top={0}
              left={0}
              handlePreviewDrag={handlePreviewDrag}
              handlePreviewDrop={handlePreviewDrop}
              handleDragOver={handleDragOver}
            />
          }
          { showRegisterModal && 
            <RegisterModal
              top={80}
              left={100}
              setShowRegisterModal={setShowRegisterModal}
            />
          }
        </div>
      </div>
      <br />
    </div>
  )
}

export default Canvas;