import React, {useState} from 'react';
import { dbCall } from '../data.js'
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const RegisterModal = ({
  setShowRegisterModal,
  top,
  left
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left 
      }
    ))

  const [regUsername, setRegUsername] = useState()
  const [regPassword, setRegPassword] = useState()

  const register = () => {
    let params = {
      username: regUsername,
      password: regPassword 
    }
    dbCall('/register', params).then(data => {
      console.log("data is ", data)
    })
    console.log("try to register", regUsername, " and ", regPassword)
  }

  return (
    <div id="register-modal" style={modalStyle} className="register-modal">
      <ModalCloseButton click={setShowRegisterModal} />
      username <input type="text" onChange={(e) => setRegUsername(e.target.value)} id="register-username"></input>
      password <input type="password" onChange={(e)=> setRegPassword(e.target.value)} id="register-password"></input>
      <button type="button" onClick={register}>Register</button>
    </div>
  )
}

export default RegisterModal