import React, {useState} from 'react';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const FontEditModal = ({
  elementProp,
  top,
  left,
  setShowFontEditModal,
  handlePreviewDrag
}) => {

  const [element, setElement] = useState(elementProp);
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const setFontFamily = (font) => {
    element.style.fontFamily = font
  }

  return (
    <div draggable="true" onDrag={handlePreviewDrag} style={modalStyle} id="style-edit-modal" className="style-edit-modal">
      <ModalCloseButton click={setShowFontEditModal} />
      <span style={{"fontFamily": "Arial", "cursor": "pointer"}} onClick={() => setFontFamily('Arial')}>Arial</span>
      <br />
      <span style={{"fontFamily": "Baskerville", "cursor": "pointer"}} onClick={() => setFontFamily('Baskerville')}>Baskerville</span>
      <br />
      <span style={{"fontFamily": "Courier", "cursor": "pointer"}} onClick={() => setFontFamily('Courier')}>Courier</span>
      <br />
      <span style={{"fontFamily": "cursive", "cursor": "pointer"}} onClick={() => setFontFamily('cursive')}>Cursive</span>
      <br />
      <span style={{"fontFamily": "fantasy", "cursor": "pointer"}} onClick={() => setFontFamily('fantasy')}>fantasy</span>
      <br />
      <span style={{"fontFamily": "Georgia", "cursor": "pointer"}} onClick={() => setFontFamily('Georgia')}>Georgia</span>
      <br />
      <span style={{"fontFamily": "Impact", "cursor": "pointer"}} onClick={() => setFontFamily('Impact')}>Impact</span>
      <br />
      <span style={{"fontFamily": "Helvetica", "cursor": "pointer"}} onClick={() => setFontFamily('Helvetica')}>Helvetica</span>
      <br />
      <span style={{"fontFamily": "monospace", "cursor": "pointer"}} onClick={() => setFontFamily('monospace')}>monospace</span>
      <br />
      <span style={{"fontFamily": "optima", "cursor": "pointer"}} onClick={() => setFontFamily('optima')}>optima</span>
      <br />
      <span style={{"fontFamily": "palatino", "cursor": "pointer"}} onClick={() => setFontFamily('palatino')}>palatino</span>
      <br />
      <span style={{"fontFamily": "rockwell", "cursor": "pointer"}} onClick={() => setFontFamily('rockwell')}>rockwell</span>
      <br />
      <span style={{"fontFamily": "sans-serif", "cursor": "pointer"}} onClick={() => setFontFamily('sans-serif')}>sans-serif</span>
      <br />
      <span style={{"fontFamily": "Tahoma", "cursor": "pointer"}} onClick={() => setFontFamily('Tahoma')}>Tahoma</span>
      <br />
      <span style={{"fontFamily": "Times", "cursor": "pointer"}} onClick={() => setFontFamily('Times')}>Times New Roman</span>
      <br />
      <span style={{"fontFamily": "Verdana", "cursor": "pointer"}} onClick={() => setFontFamily('Verdana')}>Verdana</span>
      <br />
    </div>
  )
}

export default FontEditModal