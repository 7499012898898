import React, {useState} from 'react';
import NumberScroll from './NumberScroll';
import {modalStyleBase} from '../utils.js';
import './StyleEditModal.scss';
import ModalCloseButton from './ModalCloseButton.jsx';

const StyleEditModal = ({
  elementProp,
  top,
  left,
  setShowStyleEditModal,
  handlePreviewDrag
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const [element, setElement] = useState(elementProp);

  const handleSet = (number, attr, suffix) => {
    element.style[attr] = number.toString() + suffix
  }

  return (
    <div style={modalStyle} onDrag={handlePreviewDrag} draggable="true" id="style-edit-modal" className="style-edit-modal">
      <ModalCloseButton click={setShowStyleEditModal} />
      <div className="wrapper">
        <div className="label a">width:</div>
        <div className="box b">
          <NumberScroll start={element.style.width.replace(/\D/g,'') || "100"} suffix="px" handleSet={handleSet} attr='width' />
        </div>
        <div className="label c">min-height:</div>
        <div className="box d">
          <NumberScroll start={element.style.minHeight.replace(/\D/g,'') || "50"} suffix="px" handleSet={handleSet} attr='min-height' />
        </div>
        <div className="label e">font-size:</div>
        <div className="box f">
          <NumberScroll start={element.style.fontSize.replace(/\D/g,'') || "10"} suffix="px" handleSet={handleSet} attr='fontSize' />
        </div>
        <div className="label g">border-radius:</div>
        <div className="box h">
          <NumberScroll start={element.style.borderRadius.replace(/\D/g,'') || "0"} suffix="px" handleSet={handleSet} attr='borderRadius' />
        </div>
        <div className="label i">letter-spacing:</div>
        <div className="box j">
          <NumberScroll start={element.style.letterSpacing.replace(/\D/g,'') || "0"} suffix="px" handleSet={handleSet} attr='letterSpacing' />
        </div>
        <div className="label k">border:</div>
        <div className="box l">
          <NumberScroll start={element.style.border.replace(/\D/g,'') || "1"} suffix="px solid" handleSet={handleSet} attr='border' />
        </div>
        <div className="label m">opacity:</div>
        <div className="box n">
          <NumberScroll start={element.style.opacity.replace(/\D/g,'') || "100"} suffix="%" handleSet={handleSet} attr='opacity' />
        </div>
        <div className="label o">z-index:</div>
        <div className="box p">
          <NumberScroll start="0" suffix="" handleSet={handleSet} attr='zIndex' />
        </div>
      </div>
      <br />
    </div>
  )
}

export default StyleEditModal