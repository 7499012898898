import React, {useState, useEffect} from 'react';
import './SelectEditModal.scss'
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const SelectEditModal = ({
  selectProp,
  top,
  left,
  setShowSelectEditModal,
  handlePreviewDrag
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const [select, setSelect] = useState(selectProp)
  const [inputs, setInputs] = useState()

  if (top) { selectProp.style.top = top.toString() + 'px'; }
  if (left) { selectProp.style.left = left.toString() + 'px'; }

  const saveOption = (i, optionText) => {
    select.options[i].innerHTML = optionText;
  }

  const removeOption = (e) => {
    let tempSelect = select
    tempSelect.removeChild(select.children[Number(e.target.id.split('_')[2])])
    setSelect(tempSelect)
  }

  const addOption = () => {
    // I think instead of this temp stuff maybe there could be useRef
    let tempSelect = select
    let option = document.createElement('option')
    option.value = 'new option'
    option.text = 'new option'
    tempSelect.appendChild(option)
    setSelect(tempSelect)
  }

  // need to be able to add and remove elements
  useEffect(() => {
    setInputs(Array.from(select.children).map((option,i) =>
      <Input
        option={option}
        i={i}
        saveOption={saveOption}
        removeOption={removeOption}
      />
    ))
  })

  // let inputs = [Input(select, 0), Input(select, 1)]

  return (
    // <div className="select-edit-modal">
    <div draggable="true" onDrag={handlePreviewDrag} style={modalStyle}>
      <ModalCloseButton click={setShowSelectEditModal} />
      edit select
      {inputs}
      <br />
      <span onClick={addOption}>Add Option</span>
      <br />
    </div>
  )
}

// const Input = (select, i) => {
// const Input = (option, i, saveOption, removeOption) => {
const Input = ({option, i, saveOption, removeOption}) => {
  const [optionText, setOptionText] = useState();
  const setOption = (e) => {
    setOptionText(e.target.value)
  }

  return (
    <div key={`input_${i}`}>
      <input type="text" onChange={(e) => setOption(e)}></input>
      <span id={`{set_span_${i}`} onClick={() => saveOption(i, optionText)}>set</span>
      <span id={`delete_span_${i}`} onClick={(e) => removeOption(e)}>delete</span>
    </div>
  )
}

export default SelectEditModal;