import React, {useState} from 'react';
import NumberScroll from './NumberScroll';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const CanvasModal = ({
  setShowCanvasModal,
  handlePreviewDrag,
  setShowGridModal,
  top,
  left
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left 
      }
    ))

  const handleSet = (number, attr, suffix) => {
    if (attr === 'min-height') {
      document.getElementById('preview').style.minHeight = number + suffix
    }
    if (attr === 'min-width') {
      document.getElementById('preview').style.minWidth = number + suffix
    }
  }

  return (
    <div draggable={true} id="canvas-modal" style={modalStyle} onDrag={handlePreviewDrag} className="canvas-modal">
      <ModalCloseButton click={setShowCanvasModal} />
      min-height: <NumberScroll start="600" suffix="px" handleSet={handleSet} attr="min-height" />
      <br />
      min-width: <NumberScroll start="700" suffix="px" handleSet={handleSet} attr="min-width" />
      <button className="smash-button" onClick={() => setShowGridModal(true)}>Show grid</button>
      <button className="smash-button" onClick={() => setShowGridModal(false)}>Hide grid</button>
    </div>
  )
}

export default CanvasModal