// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-edit-modal #fav-color-container {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  flex-direction: row;
}
.color-edit-modal .close-x {
  position: absolute;
  top: 5px;
  right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/ColorEditModal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;AAAJ","sourcesContent":[".color-edit-modal {\n  #fav-color-container {\n    display: flex;\n    flex-wrap: wrap;\n    width: 300px;\n    flex-direction: row;\n  }\n  .close-x {\n    position: absolute;\n    top: 5px;\n    right: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
