// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-edit-modal .wrapper {
  display: grid;
  grid-template-columns: 80px 100px;
  grid-gap: 5px;
  font-size: 14px;
}
.style-edit-modal .label {
  text-align: left;
}
.style-edit-modal .box {
  justify-self: end;
}`, "",{"version":3,"sources":["webpack://./src/components/StyleEditModal.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,iCAAA;EACA,aAAA;EACA,eAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,iBAAA;AAFJ","sourcesContent":[".style-edit-modal {\n  .wrapper {\n    display: grid;\n    grid-template-columns: 80px 100px;\n    grid-gap: 5px;\n    font-size: 14px;\n  }\n\n  .label {\n    text-align: left;\n  }\n\n  .box {\n    justify-self: end;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
