import React from 'react';

const ProjectsSelect = ({projects, handleChange}) => {
  return (
    <select className="smash-button" onChange={(e) => handleChange(e)} id='project-chooser'>
      <option>choose project</option>
      {
          projects.sort().map((thisProj, i) => {
            // return <option key={`project-key-${i}`} selected={project === thisProj}>{thisProj}</option>
            return <option key={`project-key-${i}`}>{thisProj}</option>
          })
      }
      <option value='new project'>new project</option>
    </select>
  )
}

export default ProjectsSelect
