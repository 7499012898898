import {copyStyle} from './utils'
import cookie from 'react-cookies'

let DATA_URI;
if (window.location.host === 'smashframe.com' || window.location.host === 'www.smashframe.com') {
  DATA_URI = 'https://smashframe.com:5000';
}

if (window.location.host === 'localhost:3000') {
  DATA_URI = 'http://127.0.0.1:5000';
}

export async function dbCall(url, params) {
  // console.log("dbCall: url: ", url, " params: ", params)
  const fd = new FormData();
  if (cookie.loadAll().smashsession) {
    fd.append('session_value', cookie.loadAll().smashsession)
  }
  for (const [key, val] of Object.entries(params)) {
    fd.append(key, val);
  }
  const response = await fetch( DATA_URI + url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
    },
    credentials: 'same-origin',
  body: fd
  });
  return response.json();
}

export async function fileCall(url, params) {
  // console.log("dbCall: url: ", url, " params: ", params)
  const fd = new FormData();
  if (cookie.loadAll().smashsession) {
    fd.append('session_value', cookie.loadAll().smashsession)
  }
  for (const [key, val] of Object.entries(params)) {
    fd.append(key, val);
  }
  const response = await fetch( DATA_URI + url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/octet-stream',
    },
    credentials: 'same-origin',
  body: fd
  });
  return response.blob();
}

export const getColorsFromFile = (username) => {
  let params = {
    'username': username
  }
  dbCall('/get_colors', params).then(data => {
    if (data.message === 'success') {
      return data.colorElements
    } else {
      return "error"
    }
  })
}

export const overwriteColorsInFile = (username, colorsHTML) => {
  let params = {
    'username': username,
    'colors_html': colorsHTML
  }
  dbCall('/overwrite_colors_in_file', params).then(data => {
    if (data.message === 'success') {
      console.log("success overwriting colors")
    }
  })
}

