import React, {useState} from 'react';
import NumberScroll from './NumberScroll';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const DropShadowModal = ({
  elementProp,
  top,
  left,
  setShowDropShadowModal,
  handlePreviewDrag
}) => {
  const [element, setElement] = useState(elementProp);
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const handleSet = (number, attr, suffix) => {
    if (!element.style.filter) {
      element.style.filter = "drop-shadow(rgb(0, 0, 0) 0px 0px 0px)"
    }
    let filterArr = element.style.filter.split('px');
    let offsetX = filterArr[0].replace(/\D/g, '') + 'px'
    let offsetY = filterArr[1].replace(/\D/g, '') + 'px'
    let blur = filterArr[2].replace(/\D/g, '') + 'px'
    console.log("attr is ", attr)
    switch(attr) {
      case 'offset-x':
        element.style.filter = `drop-shadow(rgb(0, 0, 0) ${number}px ${offsetY} ${blur})`
      break;
      case 'offset-y':
        element.style.filter = `drop-shadow(rgb(0, 0, 0) ${offsetX} ${number}px ${blur})`
      break;
      case 'blur':
        element.style.filter = `drop-shadow(rgb(0, 0, 0) ${offsetX} ${offsetY} ${number}px)`
      break;
    }
  }

  return (
    <div className="drop-shadow-modal" draggable="true" onDrag={handlePreviewDrag} style={modalStyle}>
      <ModalCloseButton click={setShowDropShadowModal} />
      offset-x <NumberScroll start="5" suffix="px" handleSet={handleSet} attr="offset-x" /><br />
      offset-y <NumberScroll start="5" suffix="px" handleSet={handleSet} attr="offset-y" /><br />
      blur <NumberScroll start="0" suffix="" handleSet={handleSet} attr="blur" />
      <br />
    </div>
  )
}

export default DropShadowModal