import React, {useState} from 'react';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const ConfirmationModal = ({
  top,
  left,
  handlePreviewDrag,
  setShowConfirmationModal,
  callBack,
  message,
  props
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))

  const handleDelete = () => {
    callBack(props);
    setShowConfirmationModal(false)
  }

  return (
    <div draggable={true} onDrag={handlePreviewDrag} style={modalStyle} className="confirmation-modal" id="confirmation-modal">
    <ModalCloseButton click={setShowConfirmationModal} />
    <span>{message}</span>
    <br />
    <span onClick={handleDelete}>Yes</span>
    </div>
  )
}

export default ConfirmationModal