import React, {useState} from 'react';
import {dbCall} from '../data.js'
import {modalStyleBase} from '../utils.js';
import ProjectsSelect from './ProjectsSelect'
import ModalCloseButton from './ModalCloseButton.jsx';

const NewProjectModal = ({
  setShowNewProjectModal,
  top,
  left,
  username,
  setProject,
  projects
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left
      }
    ))
  const [newProjectName, setNewProjectName] = useState();
  const [cloneProjectName, setCloneProjectName] = useState();

  const submitNewProject = () => {
    if (!newProjectName) {
      alert("you need a project name")
      return
    }
    console.log("add new project named ", newProjectName)
    let params = {
      'username': username,
      'project': newProjectName,
      'clone_project': cloneProjectName,
    }
    dbCall('/create_project', params).then(data => {
      if (data.message === 'success') {
        setProject(newProjectName)
        console.log("new project started")
      }
    })
  }

  const cloneProject = (e) => {
    setCloneProjectName(e.target.value)
  }

  return (
    <div draggable={true} style={modalStyle} className="new-project-modal">
      <ModalCloseButton click={setShowNewProjectModal} />
      Project Name<br />
      <input type="text" id="new-project-input" onChange={(e) => setNewProjectName(e.target.value)}></input>
      <br />clone project (?)
      <ProjectsSelect projects={projects} handleChange={cloneProject} />
      <br />
      <button onClick={submitNewProject}>Submit</button>
    </div>
  )
}

export default NewProjectModal