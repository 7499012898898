import React, {useState, useEffect} from 'react';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const ButtonEditModal = ({
  elementProp,
  top,
  left,
  setShowButtonEditModal,
  handlePreviewDrag
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const [element, setElement] = useState(elementProp);

  useEffect(() => {
    // sorta hate this, but without timeout hitting the 'e' for edit
    // puts an e in the input
    setTimeout(() => document.getElementById("button-modal-input").focus(), 500)
  },[])

  const handleChange = (text) => {
    // setButtonText(e.target.value)
    element.innerHTML = text
  }
  return (
    <div draggable={true} id="button-modal" onDrag={handlePreviewDrag} style={modalStyle} className="button-modal">
      <ModalCloseButton click={setShowButtonEditModal} />
      Text: <input id="button-modal-input" type="text" onChange={(e) => handleChange(e.target.value)} />
      <br />
    </div>
  )
}

export default ButtonEditModal