import React, {useState} from 'react';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const LinkModal = ({
  elementProp,
  top,
  left,
  setShowLinkModal,
  handlePreviewDrag
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))
  const [element, setElement] = useState(elementProp);

  const handleSet = (linkPage) => {
    element.setAttribute('link', linkPage)
  }

  return (
    <div draggable={true} onDrag={handlePreviewDrag} style={modalStyle} className="link-modal">
      <ModalCloseButton click={setShowLinkModal} />
      Current: {element.getAttribute('link') || "not set"}<br />
      link to &nbsp;
      <input className="smash-button" id="link-modal-input" size="2" type="text" onChange={(e) => handleSet(e.target.value)} />
      <br />
    </div>
  )
}

export default LinkModal