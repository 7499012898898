import React, {useState} from 'react';
import {modalStyleBase} from '../utils'
const GridModal = ({
  // elementProp,
  handlePreviewDrop,
  handlePreviewDrag,
  handleDragOver
}) => {
  const top = Number(document.getElementById('preview').style.top.replace(/\D/g,''))
  const left = Number(document.getElementById('preview').style.left.replace(/\D/g,''))
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
        'background': 'none',
        'backgroudImage': 'transparent',
        'opacity': '30%',
        'padding': '0',
        'zIndex': '0',
        // 'border': 'none'
      }
    ))

  // thinking rows and colums will be state variables and 
  // you can set them with a modal - maybe the canvas modal
  let rows = Math.round(document.getElementById("preview").offsetHeight/17)
  let columns = Math.round(document.getElementById("preview").offsetWidth/22)
  let dotRow = []
  let dotMatrix = []
  for (let i=0; i<rows; i+=1) {
    dotRow = []
    for (let j=0; j<columns; j+=1) {
      dotRow.push(<span style={{border: "1px dashed"}} className="dot-class" id={`dot_${i}_${j}`} key={`dot_${i}_${j}`} >&nbsp; &nbsp; &nbsp;</span>)
    }
    dotMatrix.push(dotRow)
    dotMatrix.push(<br key={`grid_br_${i}`}></br>)
  }

  const handleGridClick = () => {
    console.log("holio")
  }

  const handleGridDrag = (e) => {
    console.log("hey griddag")
    e.preventDefault()
  }

  return (
    <div
      className='grid-modal'
      id='grid-modal'
      onClick={handleGridClick}
      style={modalStyle}
      onDrag={handlePreviewDrag}
      onDragOver={handleDragOver}
      onDrop={handlePreviewDrop}
    >
     {dotMatrix}
    </div>
  )
}

export default GridModal