const Elements = () => {
  return (
    <div className="elements">
      <span style={{ backgroundImage: 'linear-gradient(180deg, rgb(249, 253, 255), rgb(255, 255, 255))'}}>Elements <span style={{ fontSize:"10px"}}>(drag and drop to canvas)</span></span><br />
      <br />
      Text input:  
      <input type="text" draggable="true" id="input" style={{"zIndex": "1"}} /><br />
      <br />
      Textarea:<br />
      <textarea name="sample-text-area" id="textArea" draggable="true" style={{"zIndex": "1"}}></textarea><br />
      <br />
      <div id="select" name="select-box-container" draggable="true" style={{"zIndex": "1"}}>
          <label htmlFor="sample-select">Select box: </label>
          <select name="sample-select" id="sample-select" draggable="true" ><option value="0">One</option><option value="1">Two</option></select>
      </div>
      <br />
      Empty Div:<br />
      <div draggable id="div" style={{"backgroundColor": "#f6f6f6", "minHeight": "70px", "width": "180px", "zIndex": "1"}} >contents</div>
      <br />
      <button type="button" draggable="true" id="button" style={{'zIndex':'1', 'display': 'inline-block'}} >Button</button>


    </div>
  )
}

export default Elements