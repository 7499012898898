import React, {useState, useEffect, useRef} from "react"
import './NumberScroll.scss'

const NumberScroll = ({start, suffix, handleSet, attr}) => {

  const [number, _setNumber] = useState(100)

  const numberRef = useRef(number)
  const setNumber = data => {
    numberRef.current = data;
    _setNumber(data)
  }

  useEffect(() => {
    setNumber(Number(start) || 0)
  }, [])

  const numberScroller = (e) => {
    setNumber(Number(numberRef.current) + Math.round(e.deltaY/4))
    handleSet(Number(numberRef.current), attr, suffix)
  }

  const handleUp = () => {
    setNumber(Number(numberRef.current) + 1)
    handleSet(Number(numberRef.current), attr, suffix)
  }

  const handleDown = () => {
    setNumber(Number(numberRef.current) - 1)
    handleSet(Number(numberRef.current), attr, suffix)
  }

  const handleInputChange = (e) => {
    setNumber(e.target.value)
    handleSet(Number(e.target.value), attr, suffix)
  }

  const disableScroll = () => {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted,
    // set this to the previous value
    window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
    };
  }

  const enableScroll = () => {
    window.onscroll = () => {};
  }

  return (
    <div className="number-scroll" onWheel={(e) => numberScroller(e)} onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
      <span><input className="smash-button" type="text" id={`num-scroll-${attr}`} value={numberRef.current || number} size="5" onChange={handleInputChange} />{suffix}</span>
      &nbsp;<img src="/up.svg" width="15px" onClick={handleUp} /> 
      <img src="/down.svg"  width="15px" onClick={handleDown} />
    </div>
  )
}

export default NumberScroll