import { dbCall, overwriteColorsInFile } from './data';

export const insetArrayFromClipPath = (clipPath) => {
  if (!clipPath) { return [0, 0, 0, 0] }
  let splitArr = clipPath.split("px").map(e => 
    Number(e.replace(/\D/g,'').trim())
  )
  for (let i=0; i<4; i++) {
    if (i >= splitArr.length) { splitArr.push(0) }
  }
  return splitArr
}

export const modalStyleBase = ({top, left, ...overrides}) => {
  return {
  position: 'absolute',
  top: (top).toString() + 'px',
  left: (left).toString() + 'px',
  padding: '10px',
  background: '#f3e9e9',
  border: '2px solid black',
  borderRadius: '5px',
  zIndex: '1',
  ...overrides
  }
}

export const copyStyle = (target, source) => {
  target.style.position = source.position || ''
  target.style.top = source.top || ''
  target.style.left = source.left || ''
  target.style.width = source.width
  target.style.height = source.height
  target.style.minHeight = source.minHeight
  target.style.filter = source.filter
  target.style.clipPath = source.clipPath
  target.style.opacity = source.opacity
  target.style.letterSpacing = source.letterSpacing
  target.style.backgroundColor = source.backgroundColor
  target.style.backgroundImage = source.backgroundImage
  source.fontFamily && (target.style.fontFamily = source.fontFamily)
  target.style.color = source.color
  target.style.border = source.border
  target.style.borderRadius = source.borderRadius
  target.style.boxShadow = source.boxShadow
  target.style.borderColor = source.borderColor
  target.style.fontSize = source.fontSize
  target.style.zIndex = source.zIndex
  target.style.display = source.display
}

export const deleteElement = ({element, username, project}) => {
  if (element.tagName === 'IMG') {
    let params = {
      'image_name': 'image_' + numberFromImageFile(element.src) + '.jpg',
      'username': username,
      'project': project
    }
    dbCall('/delete_image', params).then(data => {
      if (data.message === 'success') {
        console.log("image deleted")
      }
    })
  }
  element.remove()
  if (element.getAttribute('name') === 'added-color-box') {
    let addedBoxes = Array.from(document.getElementById("fav-color-container").children)
                          .filter(el => el.getAttribute('name') === 'added-color-box')
                          .map(el => el.outerHTML)
                          .join('')
    overwriteColorsInFile(username, addedBoxes)
  }
  if (element.getAttribute('name') === 'file-box-element' || element.getAttribute('name') === 'added-element') {
    let dataType = element.getAttribute('name') === 'file-box-element' ? 'storage' : 'html'
    let params = {
      'username': username,
      'project': project,
      'element': element.outerHTML,
      'data_type': dataType
    }
    dbCall('/delete_file_element', params).then(data => {
      if (data.message === 'success') {
        console.log("file element deleted")
      }
    })
  }
}

const numberFromImageFile = (imageFileName) => {
  return Number(imageFileName.split("image_")[1].split(".jpg")[0])
}
