// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import "antd/dist/antd.css"; */
Body {
  background-color: #eeeeee;
}

.App {
  text-align: center;
  min-height: 100vw;
  height: 100%;
  font-size: 14px;
  background-color: #eeeeee;
}
.App a {
  text-decoration: underline;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #eeeeee;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA,kCAAA;AACA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,YAAA;EAEA,eAAA;EACA,yBAAA;AAAF;AAEE;EACE,0BAAA;AAAJ;;AAIA;EACE,cAAA;EACA,oBAAA;AADF;;AAIA;EACE;IACE,4CAAA;EADF;AACF;AAIA;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,YAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE;IACE,uBAAA;EAFF;EAIA;IACE,yBAAA;EAFF;AACF","sourcesContent":["/* @import \"antd/dist/antd.css\"; */\nBody {\n  background-color: #eeeeee;\n}\n\n.App {\n  text-align: center;\n  min-height: 100vw;\n  height: 100%;\n  // width: fit-content;\n  font-size: 14px;\n  background-color: #eeeeee;\n\n  a {\n    text-decoration: underline;\n  }\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #eeeeee;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
