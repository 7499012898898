import React, {useState} from 'react';
import NumberScroll from './NumberScroll';
import {modalStyleBase} from '../utils.js';
import ModalCloseButton from './ModalCloseButton.jsx';

const CropModal = ({
  elementProp,
  top,
  left,
  setShowCropModal,
  handlePreviewDrag
}) => {
  const [modalStyle, setModalStyle] = useState(
    modalStyleBase(
      {
        'top': top,
        'left': left,
      }
    ))

  // const [linkPage, setLinkPage] = useState();
  // probably move insetArrayFromClipPath into some shared utils file
  const [element, setElement] = useState(elementProp);

  const handleSet = (number, attr, suffix) => {
    if (!element.style.clipPath) { element.style.clipPath = 'inset(0px 0px 0px 0px)'}
    let clipFirstSplit = element.style.clipPath.split('px')
    let topOffset, rightOffset, bottomOffset, leftOffset
    if (clipFirstSplit.length > 1) {
      topOffset = clipFirstSplit[0].replace(/\D/g, '').toString() + 'px'
    } else {
      topOffset = '0px'
    }
    if (clipFirstSplit.length > 2) {
      rightOffset = clipFirstSplit[1].replace(/\D/g, '').toString() + 'px'
    } else {
      rightOffset = '0px'
    }
    if (clipFirstSplit.length > 3) {
      bottomOffset = clipFirstSplit[2].replace(/\D/g, '').toString() + 'px'
    } else {
      bottomOffset = '0px'
    }
    if (clipFirstSplit.length > 4) {
      leftOffset = clipFirstSplit[3].replace(/\D/g, '').toString() + 'px'
    } else {
      leftOffset = '0px'
    }
    switch(attr) {
      case 'topOffset':
        topOffset = number.toString() + 'px'
        break;
      case 'rightOffset':
        rightOffset = number.toString() + 'px'
        break;
      case 'bottomOffset':
        bottomOffset = number.toString() + 'px'
        break;
      case 'leftOffset':
        leftOffset = number.toString() + 'px'
        break;
    }
    element.style.clipPath = `inset(${topOffset} ${rightOffset} ${bottomOffset} ${leftOffset})`

    // element.setAttribute('link', linkPage)
  }

  return (
    <div draggable={true} onDrag={handlePreviewDrag} style={modalStyle} className="crop-modal">
      <ModalCloseButton click={setShowCropModal} />
      Crop <br />
      top offset: <NumberScroll start="0" suffix="px," attr="topOffset" handleSet={handleSet}/>
      right offset: <NumberScroll start="0" suffix="px" attr="rightOffset" handleSet={handleSet}/>
      bottom offset: <NumberScroll start="0" suffix="px" attr="bottomOffset" handleSet={handleSet}/>
      left offset: <NumberScroll start="0" suffix="px" attr="leftOffset" handleSet={handleSet}/>
    </div>
  )
}

export default CropModal